import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaWhatsapp, FaMap, FaEnvelope, FaPhone } from 'react-icons/fa';
import PinkCTA from './PinkCTA';
import axiosInstance from './baseURL';

const Footer = () => {
  const [deviceCount, setDeviceCount] = useState(0);

    // Fetch device count from the API using axios
  const fetchDeviceCount = async () => {
    try {
      console.log('Fetching device count...');
      const response = await axiosInstance.get('/api/device-count'); // Use axiosInstance
      setDeviceCount(response.data.count); // Ensure you're accessing the correct data
    } catch (error) {
      console.error('Error fetching device count:', error);
    }
  };

  // Increment device count and update in the API using axios
  const incrementDeviceCount = async () => {
    try {
      const randomIncrement = Math.floor(Math.random() * 10) + 1;
      const response = await axiosInstance.patch('/api/device-count/increment', {
        increment: randomIncrement,
      });

      setDeviceCount(response.data.deviceCount.count); // Update UI with the latest count
    } catch (error) {
      console.error('Error incrementing device count:', error);
    }
  };

  useEffect(() => {
    fetchDeviceCount(); // Fetch the initial count when the component mounts

    const intervalId = setInterval(() => {
      incrementDeviceCount(); // Increment the count every 24 hours
    }, 24 * 60 * 60 * 1000); // 24-hour interval

    return () => clearInterval(intervalId); // Cleanup the interval on unmount
  }, []);

  // Animation effect when the device count updates
  useEffect(() => {
    const countElement = document.getElementById('device-count');
    if (countElement) {
      countElement.classList.add('count-transition');
      const timeoutId = setTimeout(() => {
        countElement.classList.remove('count-transition');
      }, 500);

      return () => clearTimeout(timeoutId);
    }
  }, [deviceCount]);


  return (
    <footer className="bg-gray-100 py-16 px-5 md:px-20 shadow-md w-full relative mt-40">
      {/* Footer Container */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 container mx-auto">
        {/* REGEN - Device Count */}

        <div className="space-y-6 text-center md:text-left">
          <div className="space-y-2">
            <span
              id="device-count"
              className="text-6xl font-bold text-customGreen transition-transform duration-500 ease-in-out"
            >
              {deviceCount}
            </span>
            <h2 className="text-3xl font-semibold">Trade-ins Processed</h2>
          </div>
          <p className="text-lg text-gray-600">
            Join our community of satisfied customers and trade in your old devices for cash or store credit.
          </p>
        </div>

        {/* Pages */}
        <div className="space-y-4 text-center md:text-left">
          <h6 className="text-lg font-bold font-urbanist mb-2">Trade-In</h6>
          <ul className="space-y-2">
            <li><Link to="/iphone" className="block font-urbanist text-gray-700 hover:text-gray-900">iPhone</Link></li>
            <li><Link to="/samsung" className="block font-urbanist text-gray-700 hover:text-gray-900">Samsung</Link></li>
            <li><Link to="/ipad" className="block font-urbanist text-gray-700 hover:text-gray-900">iPad</Link></li>
            <li><Link to="/macbook" className="block font-urbanist text-gray-700 hover:text-gray-900">MacBook</Link></li>
            <li><Link to="/watch" className="block font-urbanist text-gray-700 hover:text-gray-900">Watch</Link></li>
            <li><Link to="#" className="block font-urbanist text-gray-700 hover:text-gray-900">Accessories</Link></li>
          </ul>
        </div>

        {/* Utility Pages */}
        <div className="space-y-4 text-center md:text-left">
          <h6 className="text-lg font-bold font-urbanist mb-2">Legal</h6>
          <ul className="space-y-2">
            <li><Link to="/personal-data-policy" className="block font-urbanist text-gray-700 hover:text-gray-900">Protection of Personal Data</Link></li>
            <li><Link to="/trade-in-terms" className="block font-urbanist text-gray-700 hover:text-gray-900">Trade-In Terms & Conditions</Link></li>
            <li><Link to="/data-erasure-policy" className="block font-urbanist text-gray-700 hover:text-gray-900">Data Erasure Policy</Link></li>
          </ul>
        </div>

        {/* Contact Us */}
        <div className="space-y-4 text-center md:text-left">
          <h6 className="text-lg font-bold font-urbanist mb-2">Help</h6>
          <div
            className="flex items-center justify-center md:justify-start mb-2 cursor-pointer"
              onClick={() => {
    window.open('https://api.whatsapp.com/send?phone=923111005569', '_blank');
    setTimeout(() => {
      window.close();
    }, 500); // Attempt to close the window after 500ms
  }}
>
            <FaWhatsapp className="h-5 w-5 text-gray-700 mr-4" />
            <p className="text-gray-500 font-urbanist text-sm">WhatsApp Chat</p>
          </div>
          <div
  className="flex items-center justify-center md:justify-start mb-2 cursor-pointer"
  onClick={() => {
    window.open('https://maps.app.goo.gl/4CTHy4aAuLrg3N3b6', '_blank'); // Opens the Google Maps location
    setTimeout(() => {
      window.close(); // Attempts to close the tab after 500ms
    }, 500);
  }}
>
  <FaMap className="h-5 w-5 text-gray-700 mr-4" />
  <p className="text-gray-500 font-urbanist text-sm">297 St 10, Sector Y, DHA Phase 3, Lahore</p>
</div>
  {/* Email - Opens in default mail app */}
  <div
    className="flex items-center justify-center md:justify-start mb-2 cursor-pointer"
    onClick={() => window.open('mailto:support@regen.pk')}
  >
    <FaEnvelope className="h-5 w-5 text-gray-700 mr-4" />
    <p className="text-gray-500 font-urbanist text-sm">support@regen.pk</p>
  </div>

  {/* Phone - Opens dialer with number pre-filled */}
  <div
    className="flex items-center justify-center md:justify-start mb-2 cursor-pointer"
    onClick={() => window.open('tel:+923111005569')}
  >
    <FaPhone className="h-5 w-5 text-gray-700 mr-4" />
    <p className="text-gray-500 font-urbanist text-sm">(+92) 311-1005569</p>
  </div>
        </div>
      </div>

      {/* Reviews Section */}
      <div className="container mx-auto mt-8">
        <h6 className="text-lg font-bold font-urbanist mb-4 text-center">What Our Customers Say</h6>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {[
            { name: "Asad R.", rating: 5, review: "Fantastic service! Got the best value for my old iPhone." },
            { name: "Umar F.", rating: 4, review: "Quick and easy process. Will definitely use REGEN again." },
            { name: "Maham A.", rating: 5, review: "Highly recommend! Great customer support and fast payment." },
            { name: "Zahra K.", rating: 4, review: "Good experience overall. The team was very helpful!" }
          ].map((review, index) => (
            <div key={index} className="bg-gray-100 p-4 rounded-lg shadow text-center">
              <div className="flex justify-center items-center mb-2">
                <span className="text-xl font-semibold">{review.name}</span>
                <div className="ml-2 flex">
                  {[...Array(5)].map((_, i) => (
                    <svg
                      key={i}
                      xmlns="http://www.w3.org/2000/svg"
                      className={`h-5 w-5 ${i < review.rating ? 'text-yellow-500' : 'text-gray-300'}`}
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M9.049 2.927a.75.75 0 011.414 0l1.513 3.06 3.382.492a.75.75 0 01.416 1.279l-2.448 2.385.578 3.372a.75.75 0 01-1.088.791L10 13.347l-3.029 1.593a.75.75 0 01-1.088-.79l.578-3.372-2.448-2.386a.75.75 0 01.416-1.279l3.382-.492 1.513-3.06z" />
                    </svg>
                  ))}
                </div>
              </div>
              <p className="text-gray-700">{review.review}</p>
            </div>
          ))}
        </div>
      </div>

      <PinkCTA/>

      {/* Footer Bottom */}
      <div className="text-center mt-16">
        <p className="text-gray-500 font-urbanist text-sm">Copyright © REGEN | 2024</p>
      </div>
    </footer>
  );
};

export default Footer;
