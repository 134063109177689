import React, { useState, useEffect, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Header from './components/Header';
import Login from './components/Login';
import ScrollToTop from './components/ScrollToTop';
import { AnimatePresence, motion } from 'framer-motion';
import './App.css';

// Lazy load components
const IPhone = lazy(() => import('./components/iPhone'));
const Samsung = lazy(() => import('./components/Samsung'));
const IPad = lazy(() => import('./components/iPad'));
const Home = lazy(() => import('./components/Home'));
const IPhoneDetails = lazy(() => import('./components/iPhoneDetails'));
const IPadDetails = lazy(() => import('./components/iPadDetails'));
const SamsungDetails = lazy(() => import('./components/SamsungDetails'));
const MacBook = lazy(() => import('./components/Macbook'));
const MacBookDetails = lazy(() => import('./components/MacbookDetails'));
const Watch = lazy(() => import('./components/Watch'));
const WatchDetails = lazy(() => import('./components/WatchDetails'));
const DataErasurePolicy = lazy(() => import('./components/DataErasurePolicy'));
const TradeInTerms = lazy(() => import('./components/TradeInTerms'));
const PersonalDataPolicy = lazy(() => import('./components/PersonalDataPolicy'));
const ResourceListPage = lazy(() => import('./components/ResourceListPage'));
const ResourceDetailPage = lazy(() => import('./components/ResourceDetailPage'));

const Spinner = () => (
  <div className='spinner-container'>
    <div className='spinner'></div>
  </div>
);

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

    // Set loading state on every route change
  useEffect(() => {
    const handleRouteChange = async () => {
      setIsLoading(true); // Start loading spinner
      // Simulate data loading (or wait for actual data/API call)
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulate a 1-second load time
      setIsLoading(false); // Stop loading spinner after content is ready
    };

    handleRouteChange();
  }, [location.pathname]); // Trigger loading when route changes

  return (
    <div className="flex flex-col min-h-screen">
      <Suspense fallback={<Spinner />}>
        {isLoading ? (
          <div className="flex-grow flex items-center justify-center">
            <Spinner /> {/* Spinner displayed during loading */}
          </div>
        ) : (
          <>
            <Header />
            <Navbar />
            <main className="flex-grow mt-5" style={{ minHeight: 'calc(100vh - 200px)' }}>
              <AnimatePresence mode="wait">
                                                        <Routes location={location} key={location.pathname}>
                              <Route
                  path="/data-erasure-policy"
                  element={
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <DataErasurePolicy />
                    </motion.div>
                  }
                />
                                <Route
                  path="/personal-data-policy"
                  element={
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <PersonalDataPolicy />
                    </motion.div>
                  }
                />
                                <Route
                  path="/trade-in-terms"
                  element={
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <TradeInTerms />
                    </motion.div>
                  }
                />
                <Route
                  path="/"
                  element={
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <Home />
                    </motion.div>
                  }
                />
                                <Route
                  path="/login"
                  element={
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <Login />
                    </motion.div>
                  }
                />
                <Route
                  path="/iphone"
                  element={
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <IPhone />
                    </motion.div>
                  }
                />
                <Route
                  path="/ipad"
                  element={
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <IPad />
                    </motion.div>
                  }
                />
                <Route
                  path="/samsung"
                  element={
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <Samsung />
                    </motion.div>
                  }
                />
                <Route
                  path="/macbook"
                  element={
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <MacBook />
                    </motion.div>
                  }
                />
                <Route
                  path="/watch"
                  element={
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <Watch />
                    </motion.div>
                  }
                />
                <Route
                  path="/product/iphone"
                  element={
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <IPhoneDetails />
                    </motion.div>
                  }
                />
                <Route
                  path="/product/ipad"
                  element={
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <IPadDetails />
                    </motion.div>
                  }
                />
                <Route
                  path="/product/samsung"
                  element={
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <SamsungDetails />
                    </motion.div>
                  }
                />
                <Route
                  path="/product/macbook"
                  element={
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <MacBookDetails />
                    </motion.div>
                  }
                />
                <Route
                  path="/product/watch"
                  element={
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <WatchDetails />
                    </motion.div>
                  }
                />
                                <Route
                  path="/resources"
                  element={
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <ResourceListPage />
                    </motion.div>
                  }
                />
                <Route
                  path="/resource/:id"
                  element={
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <ResourceDetailPage />
                    </motion.div>
                  }
                />
              </Routes>
              </AnimatePresence>
            </main>
            <Footer />
          </>
        )}
      </Suspense>
    </div>
  );
}

function AppWithRouter() {
  return (
    <Router>
      <ScrollToTop />
      <App />
    </Router>
  );
}

export default AppWithRouter;