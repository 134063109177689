import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth, signOut } from './firebase';

const Navbar = ({ onLogoClick = () => {} }) => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  const handleMenuToggle = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleLogoClick = () => {
    onLogoClick();
    navigate('/');
  };

    // Check for authentication status
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser); // Set the current authenticated user
    });

    return () => unsubscribe(); // Cleanup subscription
  }, []);

    // Logout handler
  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/'); // Redirect to login page
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  return (
    <>
      <nav className="fixed w-full bg-white shadow-md z-50">
        <div className="mx-0 px-5 py-4 flex justify-between items-center">

          {/* User profile and logout button */}
                    {user && (
            <div className="flex items-center space-x-4">
              <p className="text-gray-700">Welcome, {user.email}!</p>
              <button
                onClick={handleLogout}
                className="bg-red-500 text-white font-semibold px-4 py-2 rounded-lg hover:bg-red-600"
              >
                Logout
              </button>
            </div>
          )}


          {/* Hamburger icon for mobile and tablets */}
          <button
            className="lg:hidden text-gray-700 focus:outline-none"
            onClick={handleMenuToggle}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </button>

          {/* Logo */}
          <div
            onClick={handleLogoClick}
            className="absolute left-1/2 transform -translate-x-1/2 cursor-pointer"
          >
            <img
              loading="lazy"
              src="https://res.cloudinary.com/dl1kjmaoq/image/upload/f_auto,q_auto/v1/static/hlgpinitoohij1kupx7z"
              alt="Logo"
              className="h-14"
            />
          </div>

          {/* Need Help button for larger screens */}
          <div className="hidden lg:flex ml-auto">
            <Link to="/resources">
              <button className="bg-black text-white rounded-full font-semibold px-6 py-2 hover:bg-gray-800">
                Need Help?
              </button>
            </Link>
          </div>
        </div>

        {/* Mobile and tablet menu */}
        {isMobileMenuOpen && (
          <>
            {/* Overlay to close menu when clicked */}
            <div
              className="fixed inset-0 bg-black opacity-50 z-40"
              onClick={handleMenuToggle}
            ></div>

            {/* Menu */}
            <div className="lg:hidden bg-white shadow-md z-50 fixed top-0 left-0 right-0 p-4 mobile-menu">
              <div className="container mx-auto py-5 flex flex-col items-center relative">
                {/* Close Button */}
                <button
                  className="absolute top-5 right-4 text-gray-700 focus:outline-none"
                  onClick={handleMenuToggle}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>

                <ul className="space-y-4 text-center">
                  <li>
                    <button
                      className="w-full py-1 px-6 text-gray-700 border border-gray-300 rounded-lg hover:bg-gray-100"
                      onClick={() => {
                        handleMenuToggle();
                        navigate('/iphone');
                      }}
                    >
                      iPhone
                    </button>
                  </li>
                  <li>
                    <button
                      className="w-full py-1 px-4 text-gray-700 border border-gray-300 rounded-lg hover:bg-gray-100"
                      onClick={() => {
                        handleMenuToggle();
                        navigate('/ipad');
                      }}
                    >
                      iPad
                    </button>
                  </li>
                  <li>
                    <button
                      className="w-full py-1 px-4 text-gray-700 border border-gray-300 rounded-lg hover:bg-gray-100"
                      onClick={() => {
                        handleMenuToggle();
                        navigate('/macbook');
                      }}
                    >
                      MacBook
                    </button>
                  </li>
                  <li>
                    <button
                      className="w-full py-1 px-4 text-gray-700 border border-gray-300 rounded-lg hover:bg-gray-100"
                      onClick={() => {
                        handleMenuToggle();
                        navigate('/watch');
                      }}
                    >
                      Watch
                    </button>
                  </li>
                  <li>
                    <button
                      className="w-full py-1 px-4 text-gray-700 border border-gray-300 rounded-lg hover:bg-gray-100"
                      onClick={() => {
                        handleMenuToggle();
                        navigate('/samsung');
                      }}
                    >
                      Samsung
                    </button>
                  </li>
                  <li>
                    <button
                      className="w-full py-1 px-4 text-gray-700 border border-gray-300 rounded-lg hover:bg-gray-100"
                      onClick={() => {
                        handleMenuToggle();
                      }}
                    >
                      Accessories
                    </button>
                  </li>
                  <li>
                    <button
                      className="w-full py-1 px-4 text-gray-700 border border-gray-300 rounded-lg hover:bg-gray-100"
                      onClick={() => {
                        handleMenuToggle();
                        navigate('/resources');
                      }}
                    >
                      Need Help?
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </>
        )}
      </nav>
    </>
  );
};

export default Navbar;
