import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut, onAuthStateChanged } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyADowUyQaUE0TChjeavwGlRNyHBZT84IEU",
  authDomain: "r-e-g-e-n-admin-nk6psf.firebaseapp.com",
  projectId: "r-e-g-e-n-admin-nk6psf",
  storageBucket: "r-e-g-e-n-admin-nk6psf.appspot.com",
  messagingSenderId: "892168367599",
  appId: "1:892168367599:web:925a8e2ee0231748f75a1a"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage(app);

export { auth, storage, signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut, onAuthStateChanged };