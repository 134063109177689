import React from 'react';

const PinkCTA = () => {
  return (
    <>
      {/* Pink CTA for Phones */}
      <div className="block sm:hidden col-span-1 relative rounded-3xl">
        <div className="bg-pink-100 rounded-3xl relative text-center flex flex-col justify-start items-center pb-64 mt-12">
          <h4 className="text-2xl md:text-3xl font-semibold font-urbanist text-gray-800 mt-5">
            Upgrade with a <br /> Certified Used Device!
          </h4>
          <div className="flex flex-col items-center mt-4">
                      <button 
  className="bg-black text-white rounded-full px-8 py-3 font-semibold font-urbanist shadow-md hover:bg-gray-800"
  onClick={() => window.open('https://regen.pk', '_blank')}
>
  Upgrade Your Device
</button>

<button 
  className="text-black font-urbanist font-semibold flex items-center space-x-2 hover:underline"
  onClick={() => window.open('https://regen.pk/pages/why-regen', '_blank')}
>
  <span>Learn more</span>
  <span>→</span>
</button>

          </div>
        </div>

        {/* Positioned image for Phones */}
        <img
          loading="lazy"
          src="https://res.cloudinary.com/dl1kjmaoq/image/upload/f_webp,fl_awebp,q_auto/v1726823324/static/tgsgjlrzkkz2khhvpt2x"
          alt="Certified Pre-Owned Device"
          className="absolute bottom-[-150px] left-[-28px] w-[380px] z-10"
        />
      </div>


      {/* Pink CTA for Tablets */}
      <div className="hidden sm:block col-span-2 relative rounded-3xl">
        <div className="relative bg-pink-100 rounded-3xl py-16 flex items-center justify-between mt-12" style={{ paddingLeft: '45%', paddingRight:'5%' }}>
        <div className='w-full flex flex-col items-start text-left space-y-4'>
          <h4 className="text-2xl md:text-3xl font-semibold font-urbanist text-gray-800 mt-0 leading-tight">
            Upgrade with a <br/> Certified Used Device!
          </h4>
          <div className="flex space-x-4">
                      <button 
  className="bg-black text-white rounded-full px-8 py-3 font-semibold font-urbanist shadow-md hover:bg-gray-800"
  onClick={() => window.open('https://regen.pk', '_blank')}
>
  Upgrade Your Device
</button>

<button 
  className="text-black font-urbanist font-semibold flex items-center space-x-2 hover:underline"
  onClick={() => window.open('https://regen.pk/pages/why-regen', '_blank')}
>
  <span>Learn more</span>
  <span>→</span>
</button>

            </div>
          </div>
        </div>

        {/* Positioned image for Tablets */}
        <img
          loading="lazy"
          src="https://res.cloudinary.com/dl1kjmaoq/image/upload/f_webp,fl_awebp,q_auto/v1726823324/static/tgsgjlrzkkz2khhvpt2x"
          alt="Certified Pre-Owned Device"
          className="absolute bottom-[-150px] left-[-90px] w-[400px] z-10"
        />
      </div>

      {/* Pink CTA for Desktop and above */}
      <div className="hidden md:hidden relative col-span-4 rounded-3xl">
        <div className="relative bg-pink-100 rounded-3xl py-16 flex items-center justify-between mt-12" style={{ paddingLeft: '40%', paddingRight: '5%' }}>
          <div className="w-full flex flex-col items-start text-left space-y-4">
            <h4 className="text-4xl md:text-5xl font-semibold font-urbanist text-gray-800 leading-tight">
              Upgrade with a <br /> Certified Used Device!
            </h4>
            <div className="flex space-x-4">
            <button 
  className="bg-black text-white rounded-full px-8 py-3 font-semibold font-urbanist shadow-md hover:bg-gray-800"
  onClick={() => window.open('https://regen.pk', '_blank')}
>
  Upgrade Your Device
</button>

<button 
  className="text-black font-urbanist font-semibold flex items-center space-x-2 hover:underline"
  onClick={() => window.open('https://regen.pk/pages/why-regen', '_blank')}
>
  <span>Learn more</span>
  <span>→</span>
</button>


            </div>
          </div>
        </div>

        {/* Positioned image for Desktop */}
        <img
          loading="lazy"
          src="https://res.cloudinary.com/dl1kjmaoq/image/upload/f_webp,fl_awebp,q_auto:best/v1726823324/static/tgsgjlrzkkz2khhvpt2x"
          alt="Certified Pre-Owned Device"
          className="absolute bottom-0 left-0 w-[400px] z-10"
        />
      </div>
    </>
  );
};

export default PinkCTA;
